import React, { useState, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const LinkScroller = ({ links }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollContainerRef = useRef(null);

  if (!links || !Array.isArray(links) || links.length === 0) {
    return null;
  }

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      setScrollPosition(container.scrollLeft + scrollAmount);
    }
  };

  return (
    <div className="relative mt-4 py-2">
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
        <button
          onClick={() => scroll('left')}
          className="p-1 bg-white rounded-full shadow-md hover:bg-gray-50 disabled:opacity-50"
          disabled={scrollPosition <= 0}
        >
          <ChevronLeft className="h-5 w-5 text-gray-600" />
        </button>
      </div>
      
      <div 
        ref={scrollContainerRef}
        className="overflow-x-auto scrollbar-hide flex items-center gap-2 px-8"
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        {links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex-shrink-0 bg-blue-50 text-blue-600 px-4 py-2 rounded-full 
                     hover:bg-blue-100 transition-colors duration-200 text-sm whitespace-nowrap"
          >
            <div className="flex items-center gap-2">
              <span>{link.title}</span>
              <span className="text-blue-400 text-xs">[{link.comment_id}]</span>
            </div>
          </a>
        ))}
      </div>

      <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
        <button
          onClick={() => scroll('right')}
          className="p-1 bg-white rounded-full shadow-md hover:bg-gray-50 disabled:opacity-50"
          disabled={scrollPosition >= (scrollContainerRef.current?.scrollWidth || 0) - (scrollContainerRef.current?.clientWidth || 0)}
        >
          <ChevronRight className="h-5 w-5 text-gray-600" />
        </button>
      </div>
    </div>
  );
};

export default LinkScroller;