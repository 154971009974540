import React from 'react';
import LinkScroller from './LinkScroller';

const getControversyColor = (rating) => {
  if (rating <= 5) {
    return `rgb(${Math.floor(255 * (rating / 5))}, 255, 0)`;
  }
  return `rgb(255, ${Math.floor(255 * (2 - rating / 5))}, 0)`;
};

const cleanPointText = (point) => {
  return point
    // Remove any citations at the end of the text
    .replace(/\s*\[\d+\](?:\s*\[\d+\])*\s*$/, '')
    // Remove any citations within the text and their surrounding spaces
    .replace(/\s*\[\d+\](?:\s*\[\d+\])*\s*/g, ' ')
    // Remove any references to comments
    .replace(/\b(?:comment|comments|as mentioned in|according to|cited in|reference)\s*\[\d+\]/gi, ' ')
    // Remove any remaining brackets with numbers
    .replace(/\[\d+\]/g, ' ')
    // Remove any double periods that might result from removing citations
    .replace(/\.\s*\./g, '.')
    // Clean up any multiple spaces (including those created by removals above)
    .replace(/\s+/g, ' ')
    // Remove spaces before punctuation
    .replace(/\s+([.,!?])/g, '$1')
    // Final trim to ensure no leading/trailing spaces
    .trim();
};

const SummaryCard = ({ summary }) => {
  if (!summary) {
    return null;
  }

  const {
    title = '',
    url = '',
    points: storyPoints = 0,
    commentCount = 0,
    story_id = '',
    summary_data = {},
    comment_map = {}
  } = summary;

  const {
    article_summary = '',
    controversy_rating = 0,
    discussion_points = [],
    links = [] 
  } = typeof summary_data === 'string' ? JSON.parse(summary_data) : summary_data;

  return (
    <article className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl mb-2">
        <a 
          href={url}
          className="text-blue-600 hover:text-blue-800 font-bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      </h2>

      <div className="flex flex-wrap items-center gap-2 text-sm text-gray-600 mb-4">
        <div className="flex items-center whitespace-nowrap">
          <span>{storyPoints} points</span>
          <span className="mx-2">|</span>
          <span>{commentCount} comments</span>
        </div>
        <span className="mx-2">|</span>
        <div className="flex items-center whitespace-nowrap">
          <a
            href={`https://news.ycombinator.com/item?id=${story_id}`}
            className="text-blue-600 hover:text-blue-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            View on HN
          </a>
        </div>
        {controversy_rating !== null && (
          <>
            <span className="mx-2">|</span>
            <div className="flex items-center whitespace-nowrap gap-2">
              <span>Controversy:</span>
              <div className="px-2 py-1 rounded font-bold text-sm text-black"
                   style={{ backgroundColor: getControversyColor(controversy_rating) }}>
                {controversy_rating}/10
              </div>
            </div>
          </>
        )}
      </div>

      {article_summary && (
        <div className="border-l-4 border-blue-500 pl-4 py-2 bg-gray-50 mb-4 text-gray-700">
          {article_summary}
        </div>
      )}

      <div className="summary-content">
        <h3 className="font-medium text-lg mb-3">Reader's Thoughts</h3>
        {commentCount < 10 ? (
          <div className="text-gray-600 italic">
            There are not enough comments to generate a summary of the reader's thoughts.
          </div>
        ) : discussion_points && discussion_points.length > 0 ? (
          discussion_points.map((point, index) => (
            <div key={index} className="flex mb-2">
              <span className="text-blue-600 font-bold mr-2">—</span>
              <span className="flex-1">
                {cleanPointText(point.point)} {' '}
                {point.citations.map((citation, i) => (
                  <React.Fragment key={i}>
                    <a 
                      href={`https://news.ycombinator.com/item?id=${comment_map[citation]}`}
                      className="text-blue-600 hover:text-blue-800"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      [{citation}]
                    </a>
                  </React.Fragment>
                ))}
              </span>
            </div>
          ))
        ) : (
          <div className="text-gray-600 italic">
            No discussion points available.
          </div>
        )}
      </div>
      {links && links.length > 0 && (
        <div className="mt-4 pt-4 border-t border-gray-200">
          <h4 className="text-sm font-medium text-gray-600 mb-2">
            Related Links
          </h4>
          <LinkScroller links={links} />
        </div>
      )}
    </article>
  );
};

export default SummaryCard;