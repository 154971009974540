import React, { useState, useEffect } from 'react';
import SummaryCard from './components/SummaryCard';

const formatTimeAgo = (date) => {
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  
  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60
  };

  for (const [unit, seconds] of Object.entries(intervals)) {
    const interval = Math.floor(diffInSeconds / seconds);
    if (interval >= 1) {
      return `${interval} ${unit}${interval === 1 ? '' : 's'} ago`;
    }
  }
  
  return 'just now';
};

const App = () => {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchSummaries = async () => {
      console.log('Fetching summaries...');
      try {
        const response = await fetch('https://10minutesaday-flask-cxhbgzdnh9brdqbg.westus-01.azurewebsites.net/api/summaries');
        if (!response.ok) throw new Error(`Failed to fetch summaries: ${response.status}`);
        const data = await response.json();
        console.log('Received data:', data);

        if (!isMounted) {
          console.log('Component unmounted, skipping update');
          return;
        }

        if (data && data.length > 0) {
          console.log('Setting summaries with length:', data.length);
          setSummaries(data);
          setLoading(false);

          const updateResponse = await fetch('https://10minutesaday-flask-cxhbgzdnh9brdqbg.westus-01.azurewebsites.net/api/last_update');
          if (updateResponse.ok) {
            const updateData = await updateResponse.json();
            if (updateData.last_updated && isMounted) {
              setLastUpdated(new Date(updateData.last_updated));
            }
          }
        } else {
          console.log('No summaries found, will retry');
          setTimeout(fetchSummaries, 10000);
        }
      } catch (err) {
        console.error('Error fetching summaries:', err);
        if (isMounted) {
          setError(`${err.message} - Please ensure the backend server is running on port 5000`);
          setLoading(false);
        }
      }
    };

    fetchSummaries();

    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array

  useEffect(() => {
    document.body.classList.add('bg-gray-100');
    return () => {
      document.body.classList.remove('bg-gray-100');
    };
  }, []);

  // Log current state
  console.log('Current state:', {
    summariesLength: summaries.length,
    loading,
    error,
    lastUpdated
  });


  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex gap-8 mb-8">
          <div className="flex-shrink-0">
            <img 
              src="/hummingbird_logo_transparent.png"
              alt="10 Minutes A Day Logo" 
              className="h-20 w-auto object-contain"
            />
          </div>
          <div>
            <h1 className="text-4xl font-bold text-gray-800 mb-2">
              10 Minutes A Day - Hacker News
            </h1>
            <div className="text-red-600 mb-4">
              {error}
            </div>
            <div className="text-sm text-gray-600 mb-4">
              Check the browser console for more details.
            </div>
            <button 
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex gap-8 mb-8">
        <div className="flex-shrink-0">
          <img 
            src="/hummingbird_logo_transparent.png"
            alt="10 Minutes A Day Logo" 
            className="h-24 w-auto object-contain"
          />
        </div>
        <div>
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            10 Minutes A Day - Hacker News
          </h1>
          <div className="text-sm text-gray-600">
            Last updated: {formatTimeAgo(lastUpdated)} by{' '}
            <a
              href="https://bsky.app/profile/skylerhartle.bsky.social"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800"
            >
              @skylerhartle
            </a>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        {summaries.map(summary => (
          <SummaryCard 
            key={summary.story_id} 
            summary={summary} 
          />
        ))}
        {loading && summaries.length === 0 && (
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto" />
            <p className="mt-4 text-gray-600">
              Initial summarization in progress. This may take a few minutes...
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;